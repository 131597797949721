var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mb-5"},[_c('h1',{staticClass:"police my-3"},[_vm._v("L'Agence")]),_c('div',{staticClass:"mx-2 d-flex justify-content-center mt-3"},[_c('div',{staticClass:"row my-auto"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-6 mt-4"},[_c('div',{staticClass:"col-12 my-3 d-flex ms-5"},[_c('svg',{staticClass:"feather feather-more-horizontal align-middle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 30 30","fill":"currentColor","stroke":"None","stroke-width":"0","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('circle',{staticClass:"pill-bleu",attrs:{"cx":"15","cy":"15","r":"15"}})]),_c('p',{staticClass:"mx-2"},[_vm._v("Spectateur")])]),_c('div',{staticClass:"col-12 my-3 d-flex ms-5"},[_c('svg',{staticClass:"feather feather-more-horizontal align-middle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 30 30","fill":"currentColor","stroke":"None","stroke-width":"0","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('circle',{staticClass:"pill-noir",attrs:{"cx":"15","cy":"15","r":"15"}})]),_c('p',{staticClass:"mx-2"},[_vm._v("Nous")])]),_c('div',{staticClass:"col-12 my-3 d-flex ms-5"},[_c('svg',{staticClass:"feather feather-more-horizontal align-middle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 30 30","fill":"currentColor","stroke":"None","stroke-width":"0","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('circle',{staticClass:"pill-jaune",attrs:{"cx":"15","cy":"15","r":"15"}})]),_c('p',{staticClass:"mx-2"},[_vm._v("Artistes")])])])]),_c('img',{staticStyle:{"width":"350px"},attrs:{"src":require("../assets/logo.svg"),"alt":""}})]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 d-none d-md-block mt-4"},[_c('div',{staticClass:"col-12 mb-4 mx-auto"},[_c('img',{attrs:{"src":require("../assets/icon/eye.svg"),"alt":""}})]),_c('div',{staticClass:"col-12 mt-4 mx-auto"},[_c('img',{attrs:{"src":require("../assets/icon/paint.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticStyle:{"width":"50%"}},[_vm._v("L’agence Kartel, c’est nous, un groupe de jeunes avec la soif d’expériences. Nous avons tous des compétences et parcours différents, mais un point commun : une sensibilité au monde de l’art. "),_c('br'),_vm._v(" Fondée en 2021, l'association Kartel s'engage résolument dans la médiation et la vulgarisation culturelle. Animés par une passion commune pour l'art, nous croyons en son pouvoir unificateur et enrichissant pour la société. Notre mission est de rendre l'art accessible à tous, de démystifier ses complexités et d'encourager la créativité sous toutes ses formes. Convaincus que chaque individu mérite l'opportunité de s'exprimer artistiquement, nous mettons en place des initiatives variées telles que des expositions, des ateliers, et des événements culturels. À travers notre engagement, nous aspirons à nourrir une communauté inclusive et vibrante, où la diversité artistique est célébrée et partagée.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col-5 my-auto"},[_c('div',{staticClass:"line"})]),_c('div',{staticClass:"col-2 my-auto p-0 mx-auto"},[_c('p',{staticClass:"pill-bleu mb-0 police fs-3"},[_vm._v("Notre Influence")])]),_c('div',{staticClass:"col-5 my-auto"},[_c('div',{staticClass:"line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center mb-3"},[_c('img',{staticClass:"mx-3 h-100 col-12 col-md-6",staticStyle:{"width":"350px"},attrs:{"src":require("../assets/images/nuit_etoile.png"),"alt":""}}),_c('div',{staticClass:"my-auto mx-3 col-12 col-md-6"},[_c('h2',{staticClass:"pill-bleu mb-3 mb-md-5 mt-3 mt-md-0 d-flex justify-content-start police"},[_vm._v("Nuit étoilée")]),_c('p',{staticClass:"text-start"},[_vm._v("La nuit étoilée représente ce que Van Gogh pouvait voir ou extrapoler de la chambre qu'il occupait lors de son séjour à l'asile de St Rémy de Provence. C'est à cette période difficile de sa vie tourmentée qu'il peint l'une des toiles les plus célèbres de l'histoire de l'art, aujourd'hui conservée au MoMA à New York.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center mb-3"},[_c('div',{staticClass:"my-auto mx-3 col-12 col-md-6"},[_c('h2',{staticClass:"pill-bleu mb-3 mb-md-5 mt-3 mt-md-0 d-flex justify-content-start police"},[_vm._v("Van Gogh")]),_c('p',{staticClass:"text-start"},[_vm._v("Vincent van Gogh est né en 1853 aux Pays-Bas et mort en 1890 en France. C’est un peintre et dessinateur néerlandais. Son œuvre pleine de naturalisme, inspirée par l'impressionnisme et le pointillisme, annonce le fauvisme et l'expressionnisme. Il est aujourd'hui considéré comme l'un des plus grands artistes de tous les temps.")])]),_c('img',{staticClass:"mx-3 col-12 col-md-6 order-first order-md-last",staticStyle:{"width":"330px"},attrs:{"src":require("../assets/images/vg.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col-5 my-auto"},[_c('div',{staticClass:"line"})]),_c('div',{staticClass:"col-2 my-auto p-0 mx-auto"},[_c('p',{staticClass:"pill-bleu mb-0 police fs-3"},[_vm._v("Les fondateurs")])]),_c('div',{staticClass:"col-5 my-auto"},[_c('div',{staticClass:"line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col mx-2"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":require("../assets/images/rubs.png"),"alt":"..."}}),_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title pill-bleu police"},[_vm._v("Ruben-Levy Konan")])])])]),_c('div',{staticClass:"col mx-2"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":require("../assets/images/emy.png"),"alt":"..."}}),_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title pill-bleu police"},[_vm._v("Emily Archer")])])])]),_c('div',{staticClass:"col mx-2"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":require("../assets/images/telmo.png"),"alt":"..."}}),_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title pill-bleu police"},[_vm._v("Telma Esmery")])])])])])
}]

export { render, staticRenderFns }